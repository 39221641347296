//
// COLORS
//

$brand-primary: #003d7d; 
$brand-secondary: #04902F; 
$brand-tertiary: #a7a9ac; 
$brand-white: #FFFFFF;
// $body: #E5E5E5;

// // Primary Colours
// $blue: #003d7d;
// $green: #04902F;
// $dark-green: #005130;
// $white: #ffffff;

// // Secondary Colours
// $dark-blue: #002d56;
// $dark-green: #004e2f;
// $metallic-silver: #a7a9ac;
// $alt-silver: #bcbec0;

// $off-white: #f9f9f9;

//
// MENU OFFSET
//

$menu-offset: 250px;