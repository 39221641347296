@import "mixins";
@import "variables";

// TYPE FACE AND WEIGHT

// AGENCY VC


@font-face {
    font-family: 'Agency VC';
    src:    url('../../assets/fonts/agency/AgencyVC-Regular.otf');
    font-weight: 400;
} 

@font-face {
    font-family: 'Agency VC';
    src:    url('../../assets/fonts/agency/AgencyVC-Bold.otf');
    font-weight: 700;
}

@font-face {
    font-family: 'Agency VC';
    src:    url('../../assets/fonts/agency/AgencyVC-Black.otf');
    font-weight: 900;
}


// AGENDA


@font-face {
    font-family: "Agenda";
    src: url("../../assets/fonts/agenda/Agenda-Regular.otf");
    font-weight: 400;
  }


// FONT FAMILY VARIABLES & MIXINS

$heading-fonts: 'Agency VC', sans-serif;
$body-fonts: 'Agenda', sans-serif;


@mixin heading-fonts {
    font-family: $heading-fonts;
    letter-spacing: 1.5px;
}

@mixin body-fonts {
    font-family: $body-fonts;
    line-height: 110%;
    letter-spacing: 1px;
}

// DEFAULT GLOBAL FONT-SIZE AND LINE HEIGHT

h1, h2, h3, h4, h5 {
    @include heading-fonts;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

h1 { 
    font-size: 2.5rem;

    @include tablet {
        // font-size: 1.6rem;
        // line-height: 44px;
    }
}

h2 { 
    font-size: 2rem;
    line-height: 2rem;

    @include tablet {
        // font-size: 2rem;
        // line-height: 2rem;
    }
}

h3 { 
    font-size: 1.25rem;

    // font-weight: 600;
    // line-height: 1.375rem;

    @include tablet { 
    font-size: 1.5rem;

        // font-weight: 700;
        // line-height: 1.5rem;
    }
}

h4 { 
    font-size: 1em;
    // line-height: 18px;
}

h5 { 
    // font-size: .6rem;
    // font-weight: 600;
    // text-transform: uppercase;
    // line-height: 1.25rem;

    @include tablet {
        // font-size: .5rem;
    }
}

p {
    @include body-fonts;
    font-size: 1.15rem;
    // line-height: 1.125rem;

    @include tablet {
        // font-size: .7rem;
    }
}
