//
// MEDIA QUERRIES
//

@mixin small {
  @media screen and (min-width: $small-breakpoint) {
    @content;
  }
};

$small-breakpoint: 415px;

@mixin medium {
  @media screen and (min-width: $medium-breakpoint) {
    @content;
  }
};

$medium-breakpoint: 491px;

@mixin tablet {
    @media screen and (min-width: $tablet-breakpoint) {
      @content;
    }
};

$tablet-breakpoint: 768px;

@mixin desk {
    @media screen and (min-width: $desk-breakpoint) {
      @content;
    }
};

$desk-breakpoint: 1000px;


//
// FLEX BOX
//

@mixin flex-container($justify: center, $align: center, $wrap: wrap, $direction: row) {
    display: flex;
    justify-content: $justify;
    align-items: $align;
    flex-wrap: $wrap;
    flex-direction: $direction;
}


//
// BORDER RADIUS
//

@mixin border-radius ($top-left: .5em, $top-right: .5em, $bottom-left: .5em, $bottom-right: .5em) {
  border-top-left-radius: $top-left;
  border-top-right-radius: $top-right;
  border-bottom-left-radius: $bottom-left;
  border-bottom-right-radius: $bottom-right;
}


//
// BUTTONS & INPUTS
//

@mixin input {
    margin: .25em auto;
    padding: .25em .5em;
    width: 100%;
    display: block;
    @include body-fonts;
    font-size: 1.1em;
    border: 1px solid $brand-tertiary;
    border-radius: .25em;



    &:hover {
        background-color: rgba(167, 169, 172, .25);
    }

    &:focus {
        border: 2px solid $brand-tertiary;
    }
}

@mixin button {
  padding: .5em 1em;
  border: none;
  border-radius: .25em .25em 0  0;

  @include body-fonts;
  font-size: 1em;
  max-width: 400px;
  color: #000;
  background-color: #FFF;
  border-bottom: 1px solid $brand-tertiary;
  &:hover {
      cursor: pointer;
  }


}

@mixin button--accordion {
  padding: .5em 1em;
  border: none;
  border-radius: .25em .25em 0  0;
  background-color: #FFF;
  border-bottom: 1px solid $brand-tertiary;
  @include body-fonts;
  font-size: 1em;
  line-height: 2em;
  
  &:hover {
      cursor: pointer;
      background-color: rgba(4, 144, 47, .25);
  }
}


//
// LOADING ANIMATION
//


@mixin loading-spinner {
  width: 1.25em;
  height: 1.25em;
  border-radius: 50%;
  border: 2.5px solid $brand-primary;
  border-top: 2.5px solid transparent;
  animation: spin 2.5s linear infinite;
  background-color: transparent;
  display: block;
  opacity: .75; 
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}