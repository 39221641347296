@import "./partials/typography.scss";
@import "./partials/variables.scss";
@import "./partials/mixins.scss";

//
// GLOBAL STYLES
//

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    outline: none;
}

body {
    background-color: $brand-white;
    font-family: sans-serif;
}

a {
    text-decoration: none;
    color: $brand-primary;
}

ul {
    list-style: none;
}

h2 {
    margin-bottom: 1em;
}

h3 {
    margin: 1.5em 0 1em 0;
}

p {
    margin-bottom: 1em;

    // & a { 
    //     margin-bottom: 2.5em;
    //     display: block;
    // }
}

button {
   @include button;
}

label {
    width: 100%;
    max-width: 400px;
}
input {
    @include input;
}



//
// MAIN
//

.main {
    margin: 0 auto;
    padding: 2em 0.75em 0 0.75em;
    max-width: 800px;

    @include tablet {
        padding: 2em 1em 0 1em;
    }

    @include desk {
        padding: 2em 3em 0 3em;
        position: relative;
        // width: calc(100% - $menu-offset);
        // width: 100%;
        left: calc($menu-offset - 100px);
    }
}

.no-scroll {
    width: 100%;
    height: 100%;
    overflow: hidden;
}




//
// HEADER
//

// .image {
//     margin: .5em 2em;
//     height: 150px;
// }
.header {
    padding: .5em 0;
    width: 100%;
    // height: 80px;
    @include flex-container(flex-start);
    // position: relative;
    background-color: $brand-primary;
    border-bottom: 2px solid $brand-tertiary;
    
    @include desk {
        // height: 198px;
    }

    &__container {

        &-logo {
            // margin-left: 3.5em;
            // width: 260px;
            @include flex-container(flex-end, center, nowrap, row);


            @include medium {
                margin-left: 1em;
            }
        }
    }

    &__logo {
        margin: 0 .5em 0 .5em;
        max-width: 40px;
        max-height: 40px;
        //  overflow: hidden;
        // position: absolute;
        // top: 0em;
        // left: 1em;

        @include desk {
            max-width: 75px;
            max-height: 75px;
        }
    }

    &__title {
        margin-top: .5em;
        padding-right: .25em;
        font-size: 1.1em;
        color: $brand-white;

        @include medium {
            font-size: 2em;
        }
    }
}

//
// MENU
//
.nav {
    padding-top: .5em;
    padding-bottom: 5em;
    letter-spacing: 1.5px;

    // position: relative;
    // top: 0;
    // @include heading-fonts;

    &--true {
        @extend .nav;
        width: 100%;

        // height: 100vh;


        position: fixed;
        background-color: $brand-white;
        height: 100vh;
        overflow: scroll;

        @include desk {
            padding-bottom: 0em;
            width: $menu-offset;
            height: 85vh;
            overflow-y: scroll;
            overflow-x: hidden;
            position: fixed;
            border-right: 1px solid $brand-tertiary;
        }
    }
    &--false {
        @extend .nav;
        display: none;
    }

    &__list {
        &--true {
            display: block;
            margin: 0 auto 2.5em 1em;
            padding-bottom: 5em;
            // width: 50%;
            // height: 400px;
            @include flex-container(flex-start, flex-start, nowrap, column);
            color: #000;
            // background-color: $brand-primary;
            // z-index: 2;
            overflow-y: scroll;
            overflow-x: hidden;

            @include desk {
                // display: block;
                padding-bottom: 0em;
                width: $menu-offset;

            }
        }
        &--false {
            display: none;
        }
    }

    &__item {
        // margin: .25em 0;
        padding:  .25em;
        font-size: .825em;
        min-width: 15em;
        text-transform: capitalize;
        @include flex-container(flex-start, center, wrap, row);

        @include desk {
            padding: 0.5em .25em;
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__link {
        padding: .35em ;

        @include desk {
            width: 175px;
            font-size: 1.05em;
        }

        &:hover {
            cursor: pointer;
            border-bottom: 1px solid $brand-tertiary;
        }
    }
}

// MENU BUTTON
.menu-button {
    margin-left: .25em;
    padding: .25em;
    // position: absolute;
    // top: 1em;
    // left: 0;
    cursor: pointer;
    width: 3em;
    // max-height: $buttonHeight;
    z-index: 10;
    background-color: transparent;
    border: none;

    // filter: drop-shadow(3px 2px 2px white);

    @include desk {
        display: none;
    }
}
.line {
    //This is default line for the Burger Menu;
    //It also has the transition details;
    // position: absolute;
    display: block;
    margin: 0.35em 0;
    border-radius: 0.5em;
    height: 0.35em;
    background-color: $brand-white;
    transition: all 400ms linear;
}
.one {
    &--true {
        height: 0.35em;
        transform: translateY(10.5px) rotate(45deg);
    }
}
.two {
    &--true {
        opacity: 0;
    }
    &--false {
        opacity: 1;
    }
}
.three {
    &--true {
        height: 0.35em;
        transform: translateY(-11.25px) rotate(-45deg);
    }
}



//
// DROPDOWN
//

.dropdown {
    margin: .25em 0 0 1em;
    width: 100%;
    font-size: 1rem;

    &__toggle {
        padding: .65em 1em 0 .25em;
        border-bottom: none; 
    }

    &__item {
        @extend .nav__item;


    }
}




//
// SUB MENU
//

.sub-nav {
    padding: 1em 0;
    // position: relative;
    // top: 0;
    // @include heading-fonts;
    letter-spacing: 1.5px;

    &--true {
        @extend .sub-nav;
        padding-top: 1em;
        // width: 75%;

        // height: 100vh;
        // overflow: hidden;
    }
    &--false {
        @extend .sub-nav;
    }

    &__list {
        &--true {
            display: block;
            margin: 0 auto;
            width: 100%;
            // height: 400px;
            @include flex-container(flex-start, center, wrap, column);
            color: #000;
            // background-color: $brand-primary;
            // z-index: 2;
        }
        &--false {
            display: none;
        }
    }

    &__item {
        margin: .25em 0;
        // padding: 0.5em 0.25em;
        width: 100%;
        @include flex-container(flex-start, center, wrap, row);
        // position: relative;
        // border-bottom: 1px solid $brand-tertiary;
    }

    &__link {
        padding: 0.25em;
        font-size: .8em;
        // font-weight: 700;
        text-transform: capitalize;
        color: #000;

        &:hover {
            cursor: pointer;
            // background-color: $brand-secondary;
            border-bottom: 1px solid $brand-tertiary;
        }
    }

    &-button {

        &:hover {
            background-color: rgba(167, 169, 172, .25);
        }
    }

    &__icon {
        margin-left: 1em;
    }
}



//
// FORM
//

.login {
    margin-top: 5em;
    width: 100%;
    @include flex-container(center, center, wrap, column);

    &__submit {
        margin-top: 1.5em;
        width: 100%;
        max-width: 400px;

        &:hover {
            background-color: rgba(167, 169, 172, .25);
        }
    }

    &__error {
        margin: 0 auto;
        display: block;
        text-align: center;
        color: rgb(255, 64, 73)
    }
}


//
// PAGE
//

.page {
    // margin-bottom: 2.5em;
}


//
// SUB PAGE
//

.sub-page {
    margin: 2em 0;
}

//
// SUB PAGE
//
.scroll-top {
    margin:  3.5em auto 1em auto;
    padding: .25em 1em;
    width: 75%;
    display: block;

    @include medium {
        padding: .5em 1em;
    }

    &--true {
        @extend .scroll-top;
        display: block;
        position: sticky;
        bottom: .15em;
        color: $brand-white;
        // background-color: rgba(167, 169, 172, .5);
        background-color: rgba(0, 61, 125, 1);

    }

    &--false {
        @extend .scroll-top;
        display: none;
    }

    &:hover {
        background-color: rgba(0, 61, 125, .75);
    }
}


//
// SECTION DROPDOWN
//
.accordion {
    margin: 1.5em 0;
    padding: 0.25em 0.5em;
    width: 100%;
    text-align: left;
    // background-color: rgba(255, 255, 255, .5);
    // border: 1px solid $brand-tertiary;
    animation: 1s ease-out fadeIn;

    @keyframes fadeIn {
        from { opacity: 0; }
        to { opacity: 1; }
      }
      


    &__container {

        &-section {
            &-name {
                padding: .5em;
                @include flex-container(flex-start, flex-start, nowrap, row);
                @include button--accordion;

                &--true {
                    @extend .accordion__container-section-name;
                    background-color: $brand-secondary;
                    color: $brand-white;
                }

                &--false {
                    @extend .accordion__container-section-name;
                }
            }
    
            &-content {
                margin-top: 1em;
                padding: .5em;

                & h2 {
                    display: none;
                }
            }
    
        }
    }

    &__toggle {
        // margin-left: .5em;
        width: 1em;
        font-size: 1.5em;
        font-weight: 800;
        // position: absolute;
        // top: 0;
        // left: 0;

        &:hover {
            cursor: pointer;
        }
    }

    &__section {

        &-name {
            margin: 0 0 0 .25em;
            // font-weight: 600;
        }
    }
}


//
// IMAGE
//

.image {

    // CONTACT IMAGES
    &--portrait {
        margin: 3.5em auto 0 auto;
        width: 100%;
        max-width: 200px;
        display: block;
    }

    // BANNER TYPE IMAGES
    &--landscape {
        margin: 0em auto 2em auto;
        width: 100%;
        display: block;
    }
}



//
// LOADING
//

.loading {

    &__container {
        padding: 2.5em 0 1em 0;
        animation: 1s ease-out fadeIn;
    }

    &__spinner {
        margin: 0 auto;
        @include loading-spinner;
        
    }
}